import { Box, Flex, Text } from "@chakra-ui/react";

const index = ({ title }) => {
  return (
    <Flex width="100%" height="100px" bg="golden" justifyContent="center">
      <Flex
        height="100%"
        alignItems="center"
        width={{ base: "90%", "2xl": "1350px" }}
      >
        <Box>
          <Text
            color="white"
            fontSize={{
              base: "24px",
              sm: "28px",
              md: "32px",
              lg: "36px",
              xl: "40px",
            }}
            fontWeight="700"
          >
            {title}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
export default index;
