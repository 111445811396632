import { object, string, number } from "yup";

export const SLIDER_IMAGE_IDS = [4956, 4955, 3344, 4954, 3344, 3343, 3345];
export const ORDER_LIST_COLUMNS = [
  "Order",
  "Date",
  "Status",
  "Total",
  "Actions",
];
export const FLAT_SHIPPING_RATE = 100;
export const PAYMENT_STATUS = {
  PENDING: "pending",
  ON_HOLD: "on-hold",
  TXN_FAILURE: "failed",
  CANCELLED: "cancelled",
  TXN_SUCCESS: "processing",
  NO_RECORD_FOUND: "pending",
};

export const FEATURE_PRODUCTS_SKU = [
  "eleva-tablet",
  "suvarnaprashan",
  "revplaq-tablet",
  "thyrin-tablet",
  "imunie-tablet",
  "lergex-tablet",
  "phylocil-tablet",
  "loswel-tablet",
];

export const BLACK = "#000";
export const WHITE = "#fff";
export const LIGHT = "#F2F4F6";
export const ITEMS_PER_PAGE_ODD = 9;
export const ITEMS_PER_PAGE_EVEN = 8;

export const COLOR = {};
export const DRAWERTITLE = "#3A3A3A";
export const DARKBACKGROUND = "#8F1838";
export const GOLDBACKGROUND = "#D0A152";
export const TITLETEXTCOLOR = "#2A3037";
export const DETAILSTEXTCOLOR = "#49586B";
export const WIDTH_BREAKPOINTS = { base: "90%", "2xl": "1400px" };

export const DEFAULT_ADDRESS_DETAILS = {
  city: "",
  state: "",
  email: "",
  phone: null,
  country: "",
  company: "",
  address1: "",
  address2: "",
  lastName: "",
  firstName: "",
  postcode: null,
};
export const DEFAULT_LOGIN_FORM = {
  email: "",
  password: "",
};
export const DEFAULT_RESET_PASSWORD_FORM = {
  password: "",
  confirmPassword: "",
};
export const DEFAULT_REGISTER_FORM = {
  email: "",
  password: "",
  confirmPassword: "",
};
export const DEFAULT_PASSWORD_CHANGE_FORM = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const FORGOT_PASSWORD_FORM_SCHEMA = object({
  email: string().email("Invalid email format").required("Email is required."),
});
export const RESET_PASSWORD_FORM_SCHEMA = object({
  password: string().required("Password is required."),
  confirmPassword: string().required(
    "Please enter password another time to confirm."
  ),
});
export const LOGIN_FORM_SCHEMA = object({
  email: string().email("Invalid email format").required("Email is required."),
  password: string().required("Password is required."),
});
export const REGISTER_FORM_SCHEMA = object({
  email: string().email("Invalid email format").required("Email is required."),
  password: string().required("Password is required."),
  confirmPassword: string().required(
    "Please enter password another time to confirm."
  ),
});

export const ADDRESS_FORM_SCHEMA = object({
  city: string().required("City is required."),
  state: string().required("State is required."),
  country: string().required("Country is required."),
  lastName: string().required("Last name is required."),
  address1: string().required("Address is required."),
  firstName: string().required("First name is required."),
  email: string().required("Email is required.").email(),
  postcode: string().required("Pincode is required.").length(6, "Pincode should be 6 digits long."),
  phone: string().required("Phone number is required.").length(10, "Phone number should be 10 digits long."),
});
export const EDIT_ACCOUNT_SCHEMA = object({
  firstName: string().required("First name is required."),
  lastName: string().required("Last name is required."),
  displayName: string().required("Display name is required."),
});
export const PASSWORD_CHANGE_SCHEMA = object({
  currentPassword: string().required("Current password is required."),
  newPassword: string().required("New password is required."),
  confirmPassword: string().required(
    "Please enter password another time to confirm."
  ),
});

export const IMAGE_SIZES =
  "(max-width: 300px) 300px, (max-width: 400px) 400px, (max-width: 600px) 600px, (max-width: 800px) 800px, (max-width: 1600px) 1600px, 100vw";

export const PRODUCT_PARENT_CATEGORIES = {
  formulations : {
    id: 354,
    slug: "formulations",
    label: "All formulations"
  },
  "medicine-form": {
    id: 355,
    slug: "medicine-form",
    label: "All forms"
  }
};

export const HOMEPAGE_IMAGES = [4805, 4800, 4797, 4806, 4802, 4807];

export const HOMEPAGE_VIDEOS = [
  "7w9lePhLbyc",
  "g1SCgKla1ww",
  "84XmxIFZjig",
  "3rlDY2-suis",
  "ykmB6RkTc4Q",
];